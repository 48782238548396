//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gql } from 'graphql-tag'
import { Language } from '@constants'
import { sync } from 'vuex-pathify'
import { slugify } from '../../utils'

const NAVBAR_ITEMS = gql`
  query {
    navbarItems(where: { isActive: { eq: true }, parentId: { eq: null } }, order: [{ order: ASC }]) {
      nodes {
        id
        name
        path
        languageId
        contentCollectionId
        contentCollection {
          slug
        }
        contentTypeId
        contentType {
          name
        }
        regionId
        region {
          name
        }
        countryId
        country {
          name
        }
        hasChildren
        children(where: { isActive: { eq: true } }, order: [{ order: ASC }]) {
          id
          name
          path
          languageId
          contentCollectionId
          contentCollection {
            slug
          }
          contentTypeId
          contentType {
            name
          }
          regionId
          region {
            name
          }
          countryId
          country {
            name
          }
        }
      }
    }
  }
`

const AccessUserLevels = Object.freeze([40, 41, 42, 50, 51, 52])

export default {
  name: 'Navbar',
  props: {
    isSearching: {
      type: Boolean,
      required: true
    },
    isCartOpen: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      menuOpen: false,
      hasBanner: true,
      isAccountDropdownOpen: false,
      bannerHeight: 0,
      scrollOffset: 0,
      navbarItems: []
    }
  },
  fetchOnServer: true,
  async fetch() {
    this.$store.set('dataFetchingCount/dataFetchingCount', this.$store.get('dataFetchingCount/dataFetchingCount') + 1)
    await this.fetchNavbarItems()
    this.$store.set('dataFetchingCount/dataFetchedCount', this.$store.get('dataFetchingCount/dataFetchedCount') + 1)
  },
  head() {
    return {
      bodyAttrs: {
        class: `has-navbar-fixed-top ${!this.hasBanner ? 'blue-background' : ''}
         ${this.isSearching ? 'search-mode' : ''} ${this.isAccountDropdownOpen ? 'account-dropdown-open' : ''}`,
        style: `padding-top: ${this.hasBanner ? this.bannerHeight + 80 : 80}px`
      }
    }
  },
  computed: {
    filters: sync('filters'),
    navbar: sync('navbar'),
    account() {
      return this.$store.get('user/user') || null
    },
    accessUserLevels() {
      if (!this.account || !this.account.userLevels) return []
      return this.account.userLevels.filter((x) => AccessUserLevels.includes(x.levelId))
    },
    navbarItemsInCurrentLanguage() {
      return this.navbarItems.filter((x) => x.languageId === this.languageId)
    },
    language() {
      return this.$store.get('language/locale') || 'en'
    },
    languageId() {
      return this.language === 'en' ? Language.ENGLISH : Language.DUTCH
    }
  },
  beforeDestroy() {
    if (this.$refs.banner) {
      window.removeEventListener('scroll', this.onScroll)
    }
    this.$nuxt.$off('topbanner')
    this.$nuxt.$off('header-banner-loaded')
  },
  mounted() {
    if (this.$refs.banner) {
      window.addEventListener('scroll', this.onScroll, { passive: true })
    }

    this.$nuxt.$on('header-banner-loaded', ({ isEmpty }) => {
      if (!isEmpty) {
        this.setBannerHeight()
      }
    })

    this.$nuxt.$on('topbanner', ({ isShow }) => {
      this.hasBanner = isShow
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
    this.$nuxt.$on('isAccountDropdownOpen', (isOpen) => {
      this.isAccountDropdownOpen = isOpen
    })
  },
  methods: {
    onScroll() {
      if (this.bannerHeight) {
        this.scrollOffset = Math.min(this.bannerHeight, window.top.scrollY)
      }
    },
    toggleMenu() {
      this.$refs.menu.toggle()
    },
    setBannerHeight() {
      if (this.$refs.banner && this.$refs.banner.$el) {
        this.bannerHeight = this.$refs.banner.$el.offsetHeight
      } else {
        this.bannerHeight = 0
      }
    },
    onHomeClick() {
      this.setFilterOptions({})
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/'
        })
      } else {
        window.location.reload()
      }
    },
    onNavigate(navbarItem, event) {
      this.setFilterOptions({
        contentCollectionId: navbarItem.contentCollectionId,
        contentTypeId: navbarItem.contentTypeId,
        regionId: navbarItem.regionId,
        countryId: navbarItem.countryId
      })

      if (navbarItem.contentCollection || navbarItem.contentType || navbarItem.region || navbarItem.country) {
        this.$store.set('navbar/selectedHeader', navbarItem.name)
      }

      event.target.parentElement.blur()
      event.target.parentElement.parentElement.parentElement.style.pointerEvents = 'none'
      setTimeout(() => {
        event.target.parentElement.parentElement.parentElement.style.pointerEvents = 'auto'
      }, 1)
    },
    getPath(navbarItem) {
      if (navbarItem.contentCollection) {
        return `/${navbarItem.contentCollection.slug}`
      } else if (navbarItem.contentType) {
        return `/${slugify(navbarItem.contentType.name)}`
      } else if (navbarItem.region) {
        return `/${slugify(navbarItem.region.name)}`
      } else if (navbarItem.country) {
        return `/${slugify(navbarItem.country.name)}`
      }

      return navbarItem.path
    },
    async fetchNavbarItems() {
      try {
        const { data } = await this.$apollo.query({
          query: NAVBAR_ITEMS,
          fetchPolicy: 'network-only'
        })

        this.navbarItems = data.navbarItems.nodes
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
