import { useStore, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import { WindowBreakpoint } from '@constants'

export default function useBreakpointWatcher() {
  const store = useStore()
  const resizeHandler = () => {
    if (window.innerWidth >= WindowBreakpoint.xl) store.set('app/currentBreakPoint', 'xl')
    else if (window.innerWidth >= WindowBreakpoint.lg) store.set('app/currentBreakPoint', 'lg')
    else if (window.innerWidth >= WindowBreakpoint.md) store.set('app/currentBreakPoint', 'md')
    else if (window.innerWidth >= WindowBreakpoint.sm) store.set('app/currentBreakPoint', 'sm')
    else store.set('app/currentBreakPoint', 'xs')
  }

  const currentBreakPoint = computed(() => store.get('app/currentBreakPoint'))

  onMounted(() => {
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
  })
  onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler)
  })

  return { currentBreakPoint, resizeHandler }
}
