export const formatDate = ($dayjs, date) => {
  if ($dayjs(date).isSame($dayjs(), 'day')) {
    return $dayjs(date).format('HH:mm').toUpperCase()
  } else if ($dayjs(date).isSame($dayjs(), 'year')) {
    return $dayjs(date).format('D MMM').toUpperCase()
  } else {
    return $dayjs(date).format('D MMM YYYY').toUpperCase()
  }
}

export const slugify = (string) => {
  if (typeof value !== 'string') {
    string = `${string}`
  }
  return string.replace('-', ' ').replace('&', ' ').replace(/\s+/g, '-').toLowerCase()
}
