//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Footer',
  computed: {
    language() {
      return this.$store.get('language/locale') || 'en'
    },
    account() {
      return this.$store.get('user/user') || null
    },
    canShow() {
      return (
        typeof navigator !== 'undefined' &&
        !navigator.userAgent.includes('Chrome-Lighthouse') &&
        !navigator.userAgent.includes('Googlebot') &&
        this.$store.get('dataFetchingCount/dataFetchingCount') === this.$store.get('dataFetchingCount/dataFetchedCount')
      )
    }
  },
  methods: {
    showRegisterModal() {
      this.$nuxt.$emit('toggleRegisterModal')
    }
  }
}
